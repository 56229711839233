import { ActionID, Icon, Link } from "components/common/basic";
import { tw } from "utils/tw";

type BannerVariant = "info" | "success" | "warning" | "danger";

type ClickAction = { onClick: () => void };
type LinkAction = {
  to: string;
  isExternal?: boolean;
};
type BannerAction = { id: ActionID; label: string } & (
  | ClickAction
  | LinkAction
);

interface Props {
  title?: string;
  body?: string | JSX.Element;
  variant?: BannerVariant;
  hideIcon?: boolean;
  verticallyCentered?: boolean;
  fullWidthContent?: boolean;
  actions?: BannerAction[];
  onClose?: () => void;
  rightColumn?: JSX.Element;
}

export default ({
  title,
  body,
  variant = "info",
  hideIcon = false,
  verticallyCentered = false,
  actions = [],
  onClose,
  rightColumn,
}: Props): JSX.Element => (
  <div
    className={tw("p-4", "w-full", "flex", "gap-2", "border", "rounded-lg", {
      "items-center": verticallyCentered,
      "bg-blue-100": variant === "info",
      "border-blue-900": variant === "info",
      "bg-badge-success": variant === "success",
      "border-success": variant === "success",
      "bg-badge-warning": variant === "warning",
      "border-badge-warning-light": variant === "warning",
      "bg-badge-error": variant === "danger",
      "border-error": variant === "danger",
    })}
  >
    {!hideIcon && (
      <Icon
        size={20}
        isFilled
        {...(() => {
          switch (variant) {
            case "info":
              return { icon: "HiInformationCircle", color: "text-blue-900" };
            case "success":
              return { icon: "HiCheckCircle", color: "text-success" };
            case "warning":
              return {
                icon: "HiExclamationCircle",
                color: "text-badge-warning-light",
              };
            case "danger":
              return { icon: "HiExclamation", color: "text-error" };

            default:
              return { icon: "HiTruck" };
          }
        })()}
      />
    )}

    <div
      className={tw(
        "flex-1",
        "flex",
        title && body ? "flex-col" : "flex-row",
        "flex-wrap",
        "gap-3"
      )}
    >
      <div
        className={tw(
          "flex",
          "flex-col",
          "gap-2",
          "md:flex-row",
          verticallyCentered ? "items-center" : "items-start",
          { [tw("flex-1")]: rightColumn }
        )}
      >
        <div className={tw("flex-1", "flex", "flex-col", "space-y-1")}>
          {title && (
            <p className={tw("text-deepBlue-900", "text-sm", "font-bold")}>
              {title}
            </p>
          )}

          {typeof body === "string" ? (
            <p className={tw("text-deepBlue-900", "text-sm")}>{body}</p>
          ) : (
            body
          )}
        </div>

        {rightColumn}
      </div>

      {actions.length > 0 && (
        <ul className={tw("flex", "space-x-3")}>
          {actions.map(({ id, label, ...actionProps }) => {
            if (Object.prototype.hasOwnProperty.call(actionProps, "onClick"))
              return (
                <li key={id} className={tw("contents")}>
                  <button
                    {...actionProps}
                    className={tw(
                      "underline",
                      "text-sm",
                      "font-semibold",
                      "text-deepBlue-900",
                      "hover:text-deepBlue-700"
                    )}
                  >
                    {label}
                  </button>
                </li>
              );

            return (
              <li key={id}>
                <Link
                  id={id}
                  {...(actionProps as LinkAction)}
                  color="black"
                  className={tw("whitespace-nowrap")}
                >
                  {label}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>

    {onClose && (
      <button
        id="banner-close_popup"
        onClick={onClose}
        className={tw(
          "self-start",
          "ml-auto",
          "hover:opacity-70",
          "p-2",
          "-m-2"
        )}
      >
        <Icon icon="HiX" isFilled size={12} color="text-gray-900" />
      </button>
    )}
  </div>
);
