import { HiOutlineCollection } from "react-icons/hi";

import { HeadingWithNotice } from "components/common/composite";
import { Page } from "components/common/wrappers";
import { useSessionQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import signmoreSymbol from "assets/symbols/signmore.svg";

import { AccountCrumbs } from "../../shared";
import DateBanners from "./DateBanners";
import SubscriptionCard from "./SubscriptionCard";

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useSessionQuery();

  if (!session) return null;

  return (
    <Page
      title={t("accountSettings_org.subscriptions.pageTitle", "Subscriptions")}
      className={tw("space-y-8", "max-w-screen-xl")}
    >
      <div className={tw("space-y-4")}>
        <AccountCrumbs
          currentPage={t(
            "accountSettings_org.subscriptions.breadcrumb",
            "Subscriptions"
          )}
        />

        <div
          className={tw(
            "mb-4",
            "flex",
            "flex-col",
            "w-full",
            "gap-4",
            "lg:flex-row",
            "lg:justify-between",
            "lg:items-start"
          )}
        >
          <HeadingWithNotice
            heading={t(
              "accountSettings_org.subscriptions.header",
              "Subscriptions"
            )}
            LeadingIcon={HiOutlineCollection}
            notice={{
              id: "orgAccountSettings_subscriptions-headerNotice",
              heading: t(
                "accountSettings_org.subscriptions.noticeHeader",
                "Subscriptions"
              ),
              body: t(
                "accountSettings_org.subscriptions.noticeDescription",
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              ),
            }}
          />
        </div>

        <DateBanners account={session.account} />
      </div>
      <div
        className={tw(
          "w-full",
          "flex",
          "flex-col",
          "gap-8",
          "lg:gap-4",
          "lg:flex-row",
          "lg:items-start"
        )}
      >
        <SubscriptionCard {...session.account.checksSubscription} />
        <SubscriptionCard {...session.account.whistleblowingSubscription} />
      </div>

      {/* TODO: Use same component as plan selection? */}
      <div className={tw("pt-6", "space-y-6")}>
        <div
          className={tw(
            "flex",
            "space-x-8",
            "items-center",
            "p-4",
            "rounded-lg",
            "bg-white",
            "border",
            "border-deepBlue-50"
          )}
        >
          <img src={signmoreSymbol} alt="signmore shield" />

          <p className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}>
            {t(
              "accountSettings_org.subscriptions.includedFree.signmore",
              "Free e-mail e-signatures"
            )}
          </p>
        </div>
      </div>
    </Page>
  );
};
