/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { PRICE_PLAN_FRAGMENT, PricePlanFragment } from "graphql/fragments";
import { QueryNodeArgs } from "graphql/types";

const PRICE_PLAN = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on PricePlan {
        ...PricePlanFragment
      }
    }
  }
  ${PRICE_PLAN_FRAGMENT}
`;

export type PricePlanQuery = { node: PricePlanFragment };

export const usePricePlanQuery = (id: string) => {
  const { data, loading } = useQuery<PricePlanQuery>(PRICE_PLAN, {
    variables: { id },
  });

  return { pricePlan: data?.node, isLoading: loading };
};

export const usePricePlanLazyQuery = () => {
  const [getPricePlanQuery, { data, loading, called }] = useLazyQuery<
    PricePlanQuery,
    QueryNodeArgs
  >(PRICE_PLAN);

  const resolveRef = useRef<(pricePlan?: PricePlanFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getPricePlan = async (id: string) => {
    getPricePlanQuery({ variables: { id } });

    return new Promise<PricePlanFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getPricePlan,
    pricePlan: data?.node,
    isLoading: loading,
  };
};
