import { useContext } from "react";
import {
  Control,
  FieldError,
  FieldErrors,
  Merge,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { HiOutlineTrash, HiPlus } from "react-icons/hi";

import { Button, Input } from "components/common/basic";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Recipient {
  firstName: string;
  lastName: string;
  email: string;
}

export interface FormWithRecipientFields {
  recipients?: Recipient[];
}

interface Props {
  control: Control<FormWithRecipientFields>;
  register: UseFormRegister<FormWithRecipientFields>;
  recipientsErrors?: Merge<FieldError, FieldErrors<Recipient[]>>;
  minRecipients?: number;
}

export default ({
  control,
  register,
  recipientsErrors,
  minRecipients = 0,
}: Props): JSX.Element => {
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("documents");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });

  const addRecipient = (
    recipient = {
      firstName: "",
      lastName: "",
      email: "",
    } as Recipient
  ) => append(recipient);

  const addDefaultRecipient = () => addRecipient();

  const addSelf = () => {
    if (!session) return;

    const firstName = session.account.defaultIdentity?.firstName ?? "";
    const lastName = session.account.defaultIdentity?.lastName ?? "";

    addRecipient({
      firstName,
      lastName,
      email: session.user.email,
    } as Recipient);
  };

  return (
    <>
      {fields.map(({ id, firstName, lastName, email }, index) => {
        const fieldWidth = tw("w-52");
        const recipientErrors = recipientsErrors?.[index];

        return (
          <div key={id} className={tw("flex", "space-x-4")}>
            <Input
              id={`recipients.${index}.firstName`}
              className={fieldWidth}
              {...register(`recipients.${index}.firstName`)}
              label={t("recipientFields.firstName.label", "First name")}
              defaultValue={firstName}
              errorMessage={recipientErrors?.firstName?.message}
            />

            <Input
              id={`recipients.${index}.lastName`}
              className={fieldWidth}
              {...register(`recipients.${index}.lastName`)}
              label={t("recipientFields.lastName.label", "Last name")}
              defaultValue={lastName}
              errorMessage={recipientErrors?.lastName?.message}
            />

            <Input
              id={`recipients.${index}.email`}
              className={fieldWidth}
              {...register(`recipients.${index}.email`)}
              label={t("recipientFields.email.label", "Email")}
              defaultValue={email}
              errorMessage={recipientErrors?.email?.message}
            />

            {fields.length > minRecipients && (
              <button
                type="button"
                className={tw("p-2", "self-start", "mt-6")}
                onClick={
                  fields.length > minRecipients
                    ? () => remove(index)
                    : undefined
                }
              >
                <HiOutlineTrash size={20} />
              </button>
            )}
          </div>
        );
      })}

      <div className={tw("flex", "space-x-4")}>
        <Button
          id="recipient_fields-add_recipient"
          onClick={addDefaultRecipient}
          variant="secondary"
          size="md"
          LeadingIcon={HiPlus}
        >
          {t(
            "createDocumentWizard.documentFiles.form.addRecipient",
            "Add recipient"
          )}
        </Button>

        <Button
          id="recipient_fields-add_me"
          onClick={addSelf}
          variant="secondary"
          size="md"
          LeadingIcon={HiPlus}
        >
          {t("createDocumentWizard.documentFiles.form.addMe", "Add me")}
        </Button>
      </div>
    </>
  );
};
