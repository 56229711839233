////////////////////////////////////////////////////////////////////////////////////////
/// Based on and using types generated by tailwindcss-classnames
/// TODO: Find out why importing from "utils" instead of "utils/tw" causes errors
////////////////////////////////////////////////////////////////////////////////////////
import clsx from "clsx";

import {
  TAccessibility,
  TBackgrounds,
  TBorders,
  TEffects,
  TFilters,
  TFlexBox,
  TGrid,
  TGrouping,
  TInset,
  TInteractivity,
  TLayout,
  TMargin,
  TSVG,
  TSizing,
  TSpacing,
  TTables,
  TTransforms,
  TTransitionsAndAnimations,
  TTypography,
} from "tw-generated";

type TClasses =
  | TAccessibility
  | TBackgrounds
  | TBorders
  | TTables
  | TEffects
  | TTransitionsAndAnimations
  | TFilters
  | TFlexBox
  | TGrid
  | TGrouping
  | TSpacing
  | TInteractivity
  | TLayout
  | TSizing
  | TSVG
  | TTransforms
  | TTypography;

type TNegativeVariants = `-${TMargin | TInset}`;
type TPseudoClasses =
  | "group-hover:"
  | "focus-within:"
  | "focus-visible:"
  | "hover:"
  | "focus:"
  | "sm:"
  | "md:"
  | "lg:"
  | "xl:"
  | "2xl:";
type TPseudoVariants = `${TPseudoClasses}${TClasses | TNegativeVariants}`;

// Intersection type prevents classname construction error (object literal cannot have multiple properties)
export type TTailwindString = "TAILWIND_STRING" & { __tailwind: true };

// For classes that are available, but not generated by tailwindcss-classnames
type TCustomClasses = "line-clamp-2" | "line-clamp-3" | "line-clamp-4";

type TKey =
  | TClasses
  | TCustomClasses
  | TPseudoVariants
  | TNegativeVariants
  | TTailwindString;

export type TArg =
  | TClasses
  | TCustomClasses
  | TPseudoVariants
  | TNegativeVariants
  | null
  | undefined
  | { [key in TKey]?: boolean }
  | TTailwindString;

export const tw = (...args: TArg[]) => clsx(...args) as TTailwindString;
