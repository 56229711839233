import { useContext } from "react";
import { HiOutlineCreditCard, HiOutlinePlus } from "react-icons/hi";

import { Button } from "components/common/basic";
import { HeadingWithNotice } from "components/common/composite";
import { Page } from "components/common/wrappers";
import { usePaymentMethodsQuery, useSessionQuery } from "graphql/queries";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { AccountCrumbs } from "../../shared";
import EmptyState from "./EmptyState";
import PaymentMethod from "./PaymentMethod";
import PaymentStatusBanner from "./PaymentStatusBanner";

export default (): JSX.Element | null => {
  const { paymentMethods } = usePaymentMethodsQuery();

  const { t } = useTranslation("account");
  const { session } = useSessionQuery();
  const { openPopup } = useContext(PopupContext);

  if (!session) return null;

  const hasPaymentMethods = paymentMethods.length > 0;
  const defaultMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.default
  );
  const otherMethods = paymentMethods.filter(
    (paymentMethod) => !paymentMethod.default
  );

  return (
    <Page
      title={t(
        "accountSettings_org.paymentMethods.pageTitle",
        "Payment methods"
      )}
      className={tw("space-y-8", "max-w-screen-xl")}
    >
      <div className={tw("space-y-4")}>
        <AccountCrumbs
          currentPage={t(
            "accountSettings_org.paymentMethods.breadcrumb",
            "Payment methods"
          )}
        />

        <div
          className={tw(
            "mb-4",
            "flex",
            "flex-col",
            "w-full",
            "gap-4",
            "lg:flex-row",
            "lg:justify-between",
            "lg:items-start"
          )}
        >
          <HeadingWithNotice
            heading={t(
              "accountSettings_org.paymentMethods.header",
              "Payment methods"
            )}
            LeadingIcon={HiOutlineCreditCard}
            notice={{
              id: "orgAccountSettings_paymentMethods-headerNotice",
              heading: t(
                "accountSettings_org.paymentMethods.noticeHeader",
                "Payment methods"
              ),
              body: t(
                "accountSettings_org.paymentMethods.noticeDescription",
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              ),
            }}
          />

          <Button
            id="accountSettings_paymentMethods-add_payment_method"
            variant="primary"
            size="md"
            LeadingIcon={HiOutlinePlus}
            onClick={() =>
              openPopup({
                id: "AddPaymentMethod",
                props: { hasPaymentMethods },
              })
            }
          >
            {t(
              "accountSettings_org.paymentMethods.addPaymentMethod",
              "New payment method"
            )}
          </Button>
        </div>

        {defaultMethod && <PaymentStatusBanner />}
      </div>

      {!hasPaymentMethods ? (
        <EmptyState />
      ) : (
        <>
          {defaultMethod && (
            <div className={tw("space-y-4")}>
              <h2 className={tw("text-deepBlue-900", "text-lg", "font-bold")}>
                {t(
                  "accountSettings_org.paymentMethods.defaultMethod.heading",
                  "Default payment method"
                )}
              </h2>

              <PaymentMethod
                paymentMethod={defaultMethod}
                canDestroy={paymentMethods.length === 1}
              />
            </div>
          )}

          {otherMethods.length > 0 && (
            <div className={tw("space-y-4")}>
              <h2 className={tw("text-deepBlue-900", "text-lg", "font-bold")}>
                {t(
                  "accountSettings_org.paymentMethods.otherMethods.heading",
                  "Other payment methods"
                )}
              </h2>
              <ul
                className={tw(
                  "w-full",
                  "flex",
                  "flex-col",
                  "gap-8",
                  "lg:gap-4",
                  "lg:flex-row",
                  "lg:items-start",
                  "lg:flex-wrap"
                )}
              >
                {otherMethods.map((paymentMethod) => (
                  <li key={paymentMethod.id}>
                    <PaymentMethod paymentMethod={paymentMethod} canDestroy />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </Page>
  );
};
