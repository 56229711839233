import { Suspense, useContext } from "react";
import { useLocation } from "react-router-dom";

import { AuthContext } from "providers/Authentication";
import { TailwindContext } from "providers/Tailwind";
import { checkIsRoutePath, useRoutePaths } from "utils";
import { tw } from "utils/tw";

import { Spinner } from "../../basic";
import AdminHeader from "./AdminHeader";
import AppHeader from "./AppHeader";
import AppTabBar from "./AppTabBar";
import OnboardingFooter from "./OnboardingFooter";
import OnboardingHeader from "./OnboardingHeader";
import SandboxHeader from "./SandboxHeader";
import SideMenu from "./SideMenu";
import { HeaderTemplate } from "./common";

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props): JSX.Element => {
  const { isLoggedIn, session } = useContext(AuthContext);
  const { isMd } = useContext(TailwindContext);

  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();

  const isHome =
    pathname === getRoutePath({ module: "common", routeName: "HOME" });
  const isPrivacy = checkIsRoutePath(pathname, {
    module: "common",
    routeName: "PRIVACY",
  });
  const isTerms = checkIsRoutePath(pathname, {
    module: "common",
    routeName: "TERMS",
  });
  const isIframeSignup = checkIsRoutePath(pathname, {
    module: "login",
    routeName: "IFRAME_SIGNUP",
  });

  // const isIframe = window.location !== window.parent.location;
  if (isHome || isPrivacy || isTerms || isIframeSignup) return <>{children}</>;

  const isEnrolmentWizard = checkIsRoutePath(pathname, {
    module: "recruitmore",
    routeName: "ENROLMENT_WIZARD",
  });
  const isCheckmoreCheckWizard = checkIsRoutePath(pathname, {
    module: "checkmore",
    routeName: "CHECK_WIZARD",
  });
  const isCheckmoreRequestWizard = checkIsRoutePath(pathname, {
    module: "checkmore",
    routeName: "REQUEST_WIZARD",
  });
  const isCheckmoreOnboardingtWizard = checkIsRoutePath(pathname, {
    module: "checkmore",
    routeName: "ONBOARDING_WIZARD",
  });
  const isCreateDocumentWizard = checkIsRoutePath(pathname, {
    module: "signmore",
    routeName: "CREATE_DOCUMENT_WIZARD",
  });
  const isSignDocumentWizard = checkIsRoutePath(pathname, {
    module: "signmore",
    routeName: "SIGN_DOCUMENT_WIZARD",
  });
  const isWbWizard = checkIsRoutePath(pathname, {
    module: "tellmore",
    routeName: "WB_WIZARD",
  });
  const isWizard =
    isEnrolmentWizard ||
    isCheckmoreCheckWizard ||
    isCheckmoreRequestWizard ||
    isCheckmoreOnboardingtWizard ||
    isCreateDocumentWizard ||
    isWbWizard ||
    isSignDocumentWizard;

  const isTokenHandler = checkIsRoutePath(pathname, {
    module: "common",
    routeName: "TOKEN_HANDLER",
  });

  if (isWizard || isTokenHandler) return <>{children}</>;

  const isAdmin = session?.administrator;

  // const hasReferrer = typeof session?.user.referrer === "string";
  // const hasOccupation = typeof session?.user.occupation === "string";
  const isConfirmed = session?.user.confirmed === true;
  const hasOutstandingPayment = Boolean(session?.account.registrationPricePlan);

  const isRestricted = isAdmin
    ? false
    : // !hasReferrer || !hasOccupation ||
      hasOutstandingPayment || !isConfirmed;

  const isOnboarding = !isLoggedIn || isRestricted;

  const isWbLogin = checkIsRoutePath(pathname, {
    module: "tellmore",
    routeName: "WB_LOGIN",
  });
  const isPublicWbReport = checkIsRoutePath(pathname, {
    module: "tellmore",
    routeName: "WB_PUBLIC_REPORT",
  });
  const isFocused = isWbLogin || isPublicWbReport;

  const isSandbox = window.location.hostname === "sandbox.my.manymore.com";

  const outerWrapperStyles = tw("min-h-screen", "flex", "flex-row", {
    "pb-16": !isFocused && !isMd && Boolean(session), // Space behind AppTabBar
  });
  const innerWrapperStyles = tw(
    "min-h-screen",
    "flex-1",
    "flex",
    "flex-col",
    "overflow-x-hidden"
  );
  if (isFocused)
    return (
      <div className={outerWrapperStyles}>
        <div className={innerWrapperStyles}>
          {isSandbox && <SandboxHeader />}

          {isAdmin && <AdminHeader session={session} />}

          <HeaderTemplate
            left={null}
            right={null}
            menuList={[]}
            showMenuToggle={false}
            isFlat
          />

          <Suspense fallback={<Spinner />}>{children}</Suspense>
        </div>
      </div>
    );

  return (
    <div className={outerWrapperStyles}>
      {isMd && isLoggedIn && session && !isRestricted && (
        <SideMenu session={session} />
      )}

      <div className={innerWrapperStyles}>
        {isSandbox && <SandboxHeader />}

        {isOnboarding ? (
          <OnboardingHeader />
        ) : (
          <>
            {isAdmin && <AdminHeader session={session} />}

            {isMd && <AppHeader />}
          </>
        )}

        <Suspense fallback={<Spinner />}>{children}</Suspense>

        {isOnboarding ? (
          <OnboardingFooter />
        ) : (
          !isMd && session && <AppTabBar />
        )}
      </div>
    </div>
  );
};
