import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useStripe } from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";

import { useCreateStripeCardMutation } from "graphql/mutations";
import { EntityTypeEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { gTag } from "utils";

export default (): null => {
  const { createStripeCard } = useCreateStripeCardMutation();

  const { session } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const stripe = useStripe();

  const setSetupIntentParams = (setupIntent: SetupIntent) => {
    const updatedSearchParams = new URLSearchParams([
      ...Array.from(searchParams.entries()).filter(
        (entry) =>
          ![
            "setup_intent",
            "setup_intent_client_secret",
            "redirect_status",
            "invoice_email",
            "entity_type",
            "set_default",
            "payment_method_setup",
          ].includes(entry[0])
      ),
      ["payment_method_setup", setupIntent.status],
    ]);
    setSearchParams(updatedSearchParams);
  };

  // Get setup intent status
  useEffect(() => {
    if (!stripe) return;

    const clientSecret = searchParams.get("setup_intent_client_secret");
    if (!clientSecret) return;

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      if (!setupIntent) return;

      // Send to BE on success
      if (setupIntent.status === "succeeded") {
        const setupIntentId = searchParams.get("setup_intent");
        const invoiceEmail = searchParams.get("invoice_email");
        const entityType =
          searchParams.get("entity_type") === EntityTypeEnum.Individual
            ? EntityTypeEnum.Individual
            : EntityTypeEnum.Organization;
        const shouldSetDefault = searchParams.get("set_default") === "true";

        if (!setupIntentId) return;

        const attributes = {
          stripeSetupIntentId: setupIntentId,
          email: invoiceEmail,
          entityType,
          default: shouldSetDefault,
        };

        createStripeCard(attributes, () => {
          setSetupIntentParams(setupIntent);

          if (session?.account.registrationPricePlan)
            gTag("conversion_event_subscribe_paid", {
              item_id: session.account.registrationPricePlan.id,
              item_name: session.account.registrationPricePlan.name,
              currency: "NOK",
              value: session.account.registrationPricePlan.price,
            });
        });
      } else {
        setSetupIntentParams(setupIntent);
      }
    });
  }, [stripe]);

  return null;
};
