import { Icon } from "components/common/basic";
import { PricePlanFragment } from "graphql/fragments";
import { FeatureEnum } from "graphql/types";
import { useTranslation } from "translations";
import { getPricePlanName } from "utils";
import { tw } from "utils/tw";

interface Props {
  pricePlan: PricePlanFragment;
  isCompact?: boolean;
}

export default ({
  pricePlan: { feature, name, price, limit, employeeLimit },
  isCompact,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const isCheckmore = feature === FeatureEnum.Checks;
  const planPrefix = `pricePlanSummary_${feature.toLowerCase()}.plan_${name.toLowerCase()}`;
  const productName = t(`${planPrefix}.product`, feature);
  const pricePerMonth = Math.round(price / 12);
  const billingCycle = t(
    "pricePlanSummary.plan.billingCycle.annual",
    "Billed annually"
  );

  if (isCompact)
    return (
      <div
        className={tw(
          "w-full",
          "p-6",
          "bg-white",
          "shadow-md",
          "rounded-lg",
          "space-y-4"
        )}
      >
        <div className={tw("flex", "items-center", "space-x-2")}>
          <Icon
            icon={isCheckmore ? "HiShieldCheck" : "HiSpeakerphone"}
            isFilled
            color="text-blue-700"
            size={24}
          />

          <p className={tw("text-2xl", "font-extrabold", "text-deepBlue-900")}>
            {productName} {getPricePlanName(feature, name, t)}
          </p>
        </div>

        {price > 0 && <p className={tw("text-deepBlue-700")}>{billingCycle}</p>}
      </div>
    );

  return (
    <div
      className={tw(
        "w-full",
        "px-4",
        "py-6",
        "bg-white",
        "shadow-md",
        "rounded-lg",
        "space-y-6"
      )}
    >
      <div className={tw("space-y-2")}>
        <div className={tw("flex", "items-center", "space-x-2")}>
          <Icon
            icon={isCheckmore ? "HiShieldCheck" : "HiSpeakerphone"}
            isFilled
            color="text-blue-700"
            size={20}
          />

          <p className={tw("text-lg", "font-bold", "text-deepBlue-700")}>
            {productName}
          </p>
        </div>

        <div className={tw("flex", "items-center", "justify-between")}>
          <p className={tw("text-2xl", "font-extrabold", "text-deepBlue-900")}>
            {getPricePlanName(feature, name, t)}
          </p>

          <p className={tw("font-semibold", "text-deepBlue-900")}>
            {t(`${planPrefix}.price`, "{{ price }},- NOK / month", {
              price: pricePerMonth,
            })}
          </p>
        </div>

        <p className={tw("text-deepBlue-700")}>
          {t("pricePlanSummary.plan.selected", "Selected plan")}
        </p>
      </div>

      <div className={tw("flex", "space-x-6")}>
        <div className={tw("flex", "space-x-2")}>
          <Icon
            icon={isCheckmore ? "HiClipboardList" : "HiUserGroup"}
            isFilled
            color="text-deepBlue-700"
            size={24}
          />

          <span className={tw("font-bold", "text-deepBlue-900")}>
            {t(`${planPrefix}.limit`, "Check {{limit}} people", {
              limit,
            })}
          </span>
        </div>

        {isCheckmore && (
          <div className={tw("flex", "space-x-2")}>
            <Icon
              icon="HiUserGroup"
              isFilled
              color="text-deepBlue-700"
              size={24}
            />

            <span className={tw("font-bold", "text-deepBlue-900")}>
              {t(`${planPrefix}.employeeLimit`, "{{limit}} users", {
                limit: employeeLimit,
              })}
            </span>
          </div>
        )}
      </div>

      {price > 0 && (
        <div
          className={tw(
            "py-3",
            "px-4",
            "bg-silver",
            "rounded-lg",
            "flex",
            "justify-between"
          )}
        >
          <p className={tw("font-semibold", "text-deepBlue-900")}>
            {t("pricePlanSummary.plan.billingCycle.label", "Subscription")}
          </p>

          <p className={tw("text-deepBlue-700")}>{billingCycle}</p>
        </div>
      )}
    </div>
  );
};
