import { gql } from "@apollo/client";

import { NoticeID } from "components/common/composite/HeadingWithNotice";
import { Account, Counts, Features, UiFlags, User } from "graphql/types";

import { ADDRESS_FRAGMENT, AddressFragment } from "./address";
import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import {
  DEPARTMENT_MEMBERSHIP_FRAGMENT,
  DepartmentMembershipFragment,
} from "./departmentMembership";
import { IDENTITY_FRAGMENT, IdentityFragment } from "./identity";
import { PRICE_PLAN_FRAGMENT, PricePlanFragment } from "./pricePlan";
import { SUBSCRIPTION_FRAGMENT, SubscriptionFragment } from "./subscription";

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFragment on Account {
    id
    slug
    wbSlug
    identities {
      ...IdentityFragment
    }
    defaultIdentity {
      ...IdentityFragment
    }
    accountType
    registrationPricePlan {
      ...PricePlanFragment
    }
    industrySector
    email
    name
    description
    logo {
      ...AttachmentFragment
    }
    symbol {
      ...AttachmentFragment
    }
    symbolDisplayPath
    hasFreelanceProfiles
    phoneNumber
    country
    language
    address {
      ...AddressFragment
    }
    uiFlags {
      hasSentCheckLists
      hasReceivedCheckLists
      shownIds
    }
    features {
      checks
      eSignatures
      invoices
      payouts
      workflows
      whistleblowing
    }
    organizationName
    organizationNumber
    pricePlan
    nonProfit
    primaryUser {
      id
      firstName
      lastName
      confirmed
      email
    }
    role
    departmentMemberships {
      ...DepartmentMembershipFragment
    }
    tag
    canManage
    canManageDepartments
    hasWhistleblowingAccess
    policyDocuments {
      ...AttachmentFragment
    }
    routineGuidelines {
      ...AttachmentFragment
    }
    whistleblowingStickerJpgDownloadPath
    whistleblowingStickerPngDownloadPath
    whistleblowingStickerPdfDownloadPath
    counts {
      checkLists
      departments
      members
      people
      wbReports

      archivedAllDocuments
      archivedCancelledDocuments
      archivedDraftDocuments
      archivedPendingDocuments
      archivedRefusedDocuments
      archivedSignedDocuments

      receivedAllDocuments
      receivedCancelledDocuments
      receivedDraftDocuments
      receivedPendingDocuments
      receivedRefusedDocuments
      receivedSignedDocuments

      sentAllDocuments
      sentCancelledDocuments
      sentDraftDocuments
      sentPendingDocuments
      sentRefusedDocuments
      sentSignedDocuments
    }
    checksSubscription {
      ...SubscriptionFragment
    }
    whistleblowingSubscription {
      ...SubscriptionFragment
    }
    deletionScheduledOn
  }
  ${PRICE_PLAN_FRAGMENT}
  ${IDENTITY_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${DEPARTMENT_MEMBERSHIP_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
`;

interface AccountUiFlags
  extends Pick<
    UiFlags,
    "hasSentCheckLists" | "hasReceivedCheckLists" | "shownIds"
  > {
  shownIds: NoticeID[];
}

export interface AccountFragment
  extends Pick<
    Account,
    | "id"
    | "slug"
    | "wbSlug"
    | "accountType"
    | "registrationPricePlan"
    | "industrySector"
    | "email"
    | "name"
    | "description"
    | "hasFreelanceProfiles"
    | "phoneNumber"
    | "country"
    | "language"
    | "symbolDisplayPath"
    | "organizationName"
    | "organizationNumber"
    | "pricePlan"
    | "nonProfit"
    | "primary"
    | "role"
    | "tag"
    | "canManage"
    | "canManageDepartments"
    | "hasWhistleblowingAccess"
    | "checksSubscription"
    | "whistleblowingSubscription"
    | "deletionScheduledOn"
    | "whistleblowingStickerJpgDownloadPath"
    | "whistleblowingStickerPngDownloadPath"
    | "whistleblowingStickerPdfDownloadPath"
  > {
  registrationPricePlan?: PricePlanFragment;
  identities?: IdentityFragment[];
  defaultIdentity?: IdentityFragment;
  symbol?: AttachmentFragment;
  logo?: AttachmentFragment;
  address?: AddressFragment;
  features: Pick<
    Features,
    | "checks"
    | "eSignatures"
    | "invoices"
    | "payouts"
    | "workflows"
    | "whistleblowing"
  >;
  uiFlags: AccountUiFlags;
  primaryUser?: Pick<
    User,
    "id" | "email" | "firstName" | "lastName" | "confirmed"
  >;
  departmentMemberships: DepartmentMembershipFragment[];
  policyDocuments: AttachmentFragment[];
  routineGuidelines: AttachmentFragment[];
  checksSubscription: SubscriptionFragment;
  whistleblowingSubscription: SubscriptionFragment;
  counts?: Pick<
    Counts,
    | "checkLists"
    | "departments"
    | "members"
    | "people"
    | "wbReports"
    | "archivedAllDocuments"
    | "archivedCancelledDocuments"
    | "archivedDraftDocuments"
    | "archivedPendingDocuments"
    | "archivedRefusedDocuments"
    | "archivedSignedDocuments"
    | "receivedAllDocuments"
    | "receivedCancelledDocuments"
    | "receivedDraftDocuments"
    | "receivedPendingDocuments"
    | "receivedRefusedDocuments"
    | "receivedSignedDocuments"
    | "sentAllDocuments"
    | "sentCancelledDocuments"
    | "sentDraftDocuments"
    | "sentPendingDocuments"
    | "sentRefusedDocuments"
    | "sentSignedDocuments"
  >;
}
