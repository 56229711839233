import { useContext } from "react";

import { Button, Icon, PaymentMethodIcon } from "components/common/basic";
import { PaymentMethodInfo } from "components/common/composite";
import { PaymentMethodFragment } from "graphql/fragments";
import {
  useDestroyPaymentMethodMutation,
  useSetDefaultPaymentMethoddMutation,
} from "graphql/mutations";
import { useSessionQuery } from "graphql/queries";
import { PaymentMethodTypeEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { StripeCardBrand } from "utils";
import { tw } from "utils/tw";

interface Props {
  paymentMethod: PaymentMethodFragment;
  canDestroy?: boolean;
}

export default ({
  paymentMethod,
  canDestroy = false,
}: Props): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useSessionQuery();
  const { openPopup } = useContext(PopupContext);
  const { setDefaultPaymentMethod, isLoading: isSettingDefault } =
    useSetDefaultPaymentMethoddMutation();
  const { destroyPaymentMethod, isLoading: isDestroying } =
    useDestroyPaymentMethodMutation();
  const isLoading = isSettingDefault || isDestroying;

  if (!session) return null;

  const isCreditCard = paymentMethod.type === PaymentMethodTypeEnum.StripeCard;

  const methodLine1 = paymentMethod.pending
    ? t("accountSettings_paymentMethods.paymentMethod.pending.line1", "Pending")
    : isCreditCard
      ? `•••• •••• •••• ${paymentMethod.cardLast4}`
      : paymentMethod.name;
  const methodLine2 = paymentMethod.pending
    ? t(
        "accountSettings_paymentMethods.paymentMethod.pending.line2",
        "Processing details..."
      )
    : isCreditCard
      ? t(
          "accountSettings_paymentMethods.paymentMethod.expiration",
          "Expires {{ month }}/{{ year }}",
          {
            month: `0${paymentMethod.cardExpMonth}`.slice(-2),
            year: `${paymentMethod.cardExpYear}`.slice(-2),
          }
        )
      : paymentMethod.organizationNumber;

  return (
    <div
      className={tw(
        "w-60",
        "p-4",
        "rounded-lg",
        "border",
        "border-deepBlue-50"
      )}
    >
      <div className={tw("mb-2", "flex", "justify-between", "items-center")}>
        <PaymentMethodIcon
          type={
            isCreditCard
              ? (paymentMethod.cardBrand as StripeCardBrand)
              : "invoice"
          }
        />

        {canDestroy && (
          <button
            className={tw("hover:opacity-70", "p-2", "-m-2")}
            onClick={() =>
              openPopup({
                id: "ConfirmAction",
                props: {
                  onConfirmation: () => destroyPaymentMethod(paymentMethod.id),
                  confirmationHeading: t(
                    "accountSettings_paymentMethods.paymentMethod.deleteConfirmation.heading",
                    "Are you sure you want to delete this payment method?"
                  ),
                  confirmationLabel: t(
                    "accountSettings_paymentMethods.paymentMethod.deleteConfirmation.label",
                    "Delete"
                  ),
                  confirmationBody: (
                    <PaymentMethodInfo paymentMethod={paymentMethod} />
                  ),
                  isDestructive: true,
                },
              })
            }
          >
            <Icon icon="HiTrash" isFilled color="text-gray-400" size={20} />
          </button>
        )}
      </div>

      <p className={tw("text-deepBlue-900", "font-semibold")}>{methodLine1}</p>

      <p className={tw("text-deepBlue-700")}>{methodLine2}</p>

      <div className={tw("mt-6", "flex", "justify-between", "items-center")}>
        <Button
          id="accountSettings_paymentMethods-edit_payment_method"
          variant="tertiary"
          size="md"
          onClick={() =>
            openPopup({ id: "EditPaymentMethod", props: { paymentMethod } })
          }
          disabled={paymentMethod.pending}
        >
          {t("accountSettings_paymentMethods.paymentMethod.edit", "Edit")}
        </Button>

        {paymentMethod.default ? (
          <div className={tw("flex", "space-x-1", "items-center")}>
            <Icon
              icon="HiCheckCircle"
              isFilled
              color="text-success"
              size={16}
            />

            <p className={tw("text-sm", "text-deepBlue-900", "font-semibold")}>
              {t(
                "accountSettings_paymentMethods.paymentMethod.default",
                "Default"
              )}
            </p>
          </div>
        ) : (
          <button
            id="accountSettings_paymentMethods-set_default_method"
            className={tw(
              "text-sm",
              "text-deepBlue-900",
              "font-semibold",
              "underline"
            )}
            onClick={() => setDefaultPaymentMethod(paymentMethod.id)}
            disabled={isLoading}
          >
            {t(
              "accountSettings_paymentMethods.paymentMethod.setDefault",
              "Set as default"
            )}
          </button>
        )}
      </div>
    </div>
  );
};
