import { PopupWrapper } from "components/common/popups";
import { AccountFragment } from "graphql/fragments";
import { useTranslation } from "translations";

import { CheckoutForm } from "../composite";

interface Props {
  hasPaymentMethods: boolean;

  hasPrevious: boolean;
  onSuccess?: (account: AccountFragment) => void;
}

export default ({
  hasPaymentMethods,
  onSuccess,
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <PopupWrapper
      {...props}
      title={t("popup.addPaymentMethod.title", "Add payment method")}
      variant="slideOver"
    >
      <CheckoutForm
        isFlat
        hasPaymentMethods={hasPaymentMethods}
        submitLabel={t("popup.addPaymentMethod.submit", "Add payment method")}
      />
    </PopupWrapper>
  );
};
