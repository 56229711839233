import { lazy } from "react";

import {
  AccountInfo,
  PageSelection,
  PaymentMethods,
  PaymentPlan,
  PayoutSettings,
  PolicyDocuments,
  Subscriptions,
  Team,
} from "./accountSettings";

const Overview = lazy(() => import("./Overview"));
const Person = lazy(() => import("./Person"));
const ScheduledDeletion = lazy(() => import("./ScheduledDeletion"));

export {
  AccountInfo,
  PageSelection,
  PaymentMethods,
  PaymentPlan,
  PayoutSettings,
  Overview,
  Person,
  PolicyDocuments,
  Subscriptions,
  ScheduledDeletion,
  Team,
};
