import { useContext, useEffect, useState } from "react";

import { Divider, Icon } from "components/common/basic";
import { Banner } from "components/common/composite";
import { PricePlanFragment } from "graphql/fragments";
import { TailwindContext } from "providers/Tailwind";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import PricePlan from "./PricePlan";

interface Props {
  pricePlan?: PricePlanFragment;
}

export default ({ pricePlan }: Props): JSX.Element => {
  const [isCompact, setIsCompact] = useState(false);

  const { isMd } = useContext(TailwindContext);
  const { t } = useTranslation("common");

  // Show/hide summary when size changes
  useEffect(() => {
    setIsCompact(!isMd);
  }, [isMd]);

  // Remove when VAT becomes available
  const showPrice = false;

  return (
    <div className={tw("space-y-6")}>
      {isMd && (
        <h2 className={tw("text-lg", "font-bold")}>
          {t("pricePlanSummary.heading", "Summary")}
        </h2>
      )}

      {pricePlan ? (
        <>
          <PricePlan pricePlan={pricePlan} isCompact={isCompact} />

          {pricePlan.price > 0 && (
            <>
              {!isCompact && showPrice && (
                <>
                  <Divider />

                  <div className={tw("flex", "justify-between")}>
                    <p className={tw("text-deepBlue-700")}>
                      {t(
                        "pricePlanSummary.billingSection.subtotal",
                        "Subtotal"
                      )}
                    </p>

                    <p className={tw("text-deepBlue-900", "font-semibold")}>
                      {pricePlan.price},- $$$
                    </p>
                  </div>

                  <div className={tw("flex", "justify-between")}>
                    <p className={tw("text-deepBlue-700")}>
                      {t("pricePlanSummary.billingSection.vat", "VAT (25%)")}
                    </p>

                    <p className={tw("text-deepBlue-900", "font-semibold")}>
                      {pricePlan.price},- $$$
                    </p>
                  </div>
                </>
              )}

              {showPrice && (
                <>
                  <Divider />

                  <div className={tw("flex", "justify-between")}>
                    <p className={tw("text-lg", "text-deepBlue-700")}>
                      {t(
                        "pricePlanSummary.billingSection.dueDate",
                        "Due today"
                      )}
                    </p>

                    <p
                      className={tw(
                        "text-lg",
                        "text-deepBlue-900",
                        "font-bold"
                      )}
                    >
                      {pricePlan.price},- $$$
                    </p>
                  </div>
                </>
              )}

              {!isCompact && (
                <p className={tw("text-deepBlue-500")}>
                  {t(
                    "pricePlanSummary.billingSection.billingCycle.annual",
                    "You will be charged annually starting from today until you cancel the subscription."
                  )}
                </p>
              )}

              {!isMd && (
                <button
                  onClick={() => setIsCompact(!isCompact)}
                  className={tw("flex", "items-center", "space-x-1")}
                >
                  <span className={tw("font-semibold", "text-deepBlue-900")}>
                    {isCompact
                      ? t(
                          "pricePlanSummary.billingSection.showSummary",
                          "Show summary"
                        )
                      : t(
                          "pricePlanSummary.billingSection.hideSummary",
                          "Hide summary"
                        )}
                  </span>

                  <Icon
                    size={16}
                    color="text-deepBlue-900"
                    isFilled
                    icon={isCompact ? "HiChevronDown" : "HiChevronUp"}
                  />
                </button>
              )}
            </>
          )}
        </>
      ) : (
        <Banner
          variant="danger"
          title={t(
            "pricePlanSummary.errorBanner.title",
            "We are unable to load the subscription!"
          )}
          body={t(
            "pricePlanSummary.errorBanner.body",
            "We are unable to detect the subscription. Please check your internet connection and or re-load this page. If the issue persists, contact support."
          )}
          actions={[
            {
              id: "signup-contact_support",
              label: t(
                "pricePlanSummary.errorBanner.action.contactSupport.label",
                "Contact support"
              ),
              to: t(
                "pricePlanSummary.errorBanner.action.contactSupport.link",
                "mailto:support@manymore.com"
              ),
              isExternal: true,
            },
          ]}
        />
      )}
    </div>
  );
};
