export default (): {
  countryCode: string;
  phonePrefix: string;
  flag: string;
}[] => [
  {
    countryCode: "NO",
    phonePrefix: "+47",
    flag: "🇳🇴",
  },
  {
    countryCode: "SE",
    phonePrefix: "+46",
    flag: "🇸🇪",
  },
  {
    countryCode: "DK",
    phonePrefix: "+45",
    flag: "🇩🇰",
  },
  {
    countryCode: "FI",
    phonePrefix: "+358",
    flag: "🇫🇮",
  },
  {
    countryCode: "AD",
    phonePrefix: "+376",
    flag: "🇦🇩",
  },
  {
    countryCode: "AE",
    phonePrefix: "+971",
    flag: "🇦🇪",
  },
  {
    countryCode: "AF",
    phonePrefix: "+93",
    flag: "🇦🇫",
  },
  {
    countryCode: "AG",
    phonePrefix: "+1268",
    flag: "🇦🇬",
  },
  {
    countryCode: "AI",
    phonePrefix: "+1264",
    flag: "🇦🇮",
  },
  {
    countryCode: "AL",
    phonePrefix: "+355",
    flag: "🇦🇱",
  },
  {
    countryCode: "AM",
    phonePrefix: "+374",
    flag: "🇦🇲",
  },
  {
    countryCode: "AN",
    phonePrefix: "+599",
    flag: "🇧🇶",
  },
  {
    countryCode: "AO",
    phonePrefix: "+244",
    flag: "🇦🇴",
  },
  {
    countryCode: "AQ",
    phonePrefix: "+672",
    flag: "🇦🇶",
  },
  {
    countryCode: "AR",
    phonePrefix: "+54",
    flag: "🇦🇷",
  },
  {
    countryCode: "AS",
    phonePrefix: "+1684",
    flag: "🇦🇸",
  },
  {
    countryCode: "AT",
    phonePrefix: "+43",
    flag: "🇦🇹",
  },
  {
    countryCode: "AU",
    phonePrefix: "+61",
    flag: "🇦🇺",
  },
  {
    countryCode: "AW",
    phonePrefix: "+297",
    flag: "🇦🇼",
  },
  {
    countryCode: "AX",
    phonePrefix: "+358",
    flag: "🇦🇽",
  },
  {
    countryCode: "AZ",
    phonePrefix: "+994",
    flag: "🇦🇿",
  },
  {
    countryCode: "BA",
    phonePrefix: "+387",
    flag: "🇧🇦",
  },
  {
    countryCode: "BB",
    phonePrefix: "+1246",
    flag: "🇧🇧",
  },
  {
    countryCode: "BD",
    phonePrefix: "+880",
    flag: "🇧🇩",
  },
  {
    countryCode: "BE",
    phonePrefix: "+32",
    flag: "🇧🇪",
  },
  {
    countryCode: "BF",
    phonePrefix: "+226",
    flag: "🇧🇫",
  },
  {
    countryCode: "BG",
    phonePrefix: "+359",
    flag: "🇧🇬",
  },
  {
    countryCode: "BH",
    phonePrefix: "+973",
    flag: "🇧🇭",
  },
  {
    countryCode: "BI",
    phonePrefix: "+257",
    flag: "🇧🇮",
  },
  {
    countryCode: "BJ",
    phonePrefix: "+229",
    flag: "🇧🇯",
  },
  {
    countryCode: "BL",
    phonePrefix: "+590",
    flag: "🇧🇱",
  },
  {
    countryCode: "BM",
    phonePrefix: "+1441",
    flag: "🇧🇲",
  },
  {
    countryCode: "BN",
    phonePrefix: "+673",
    flag: "🇧🇳",
  },
  {
    countryCode: "BO",
    phonePrefix: "+591",
    flag: "🇧🇴",
  },
  {
    countryCode: "BR",
    phonePrefix: "+55",
    flag: "🇧🇷",
  },
  {
    countryCode: "BS",
    phonePrefix: "+1242",
    flag: "🇧🇸",
  },
  {
    countryCode: "BT",
    phonePrefix: "+975",
    flag: "🇧🇹",
  },
  {
    countryCode: "BW",
    phonePrefix: "+267",
    flag: "🇧🇼",
  },
  {
    countryCode: "BY",
    phonePrefix: "+375",
    flag: "🇧🇾",
  },
  {
    countryCode: "BZ",
    phonePrefix: "+501",
    flag: "🇧🇿",
  },
  {
    countryCode: "CA",
    phonePrefix: "+1",
    flag: "🇨🇦",
  },
  {
    countryCode: "CC",
    phonePrefix: "+61",
    flag: "🇨🇨",
  },
  {
    countryCode: "CD",
    phonePrefix: "+243",
    flag: "🇨🇩",
  },
  {
    countryCode: "CF",
    phonePrefix: "+236",
    flag: "🇨🇫",
  },
  {
    countryCode: "CG",
    phonePrefix: "+242",
    flag: "🇨🇬",
  },
  {
    countryCode: "CH",
    phonePrefix: "+41",
    flag: "🇨🇭",
  },
  {
    countryCode: "CI",
    phonePrefix: "+225",
    flag: "🇨🇮",
  },
  {
    countryCode: "CK",
    phonePrefix: "+682",
    flag: "🇨🇰",
  },
  {
    countryCode: "CL",
    phonePrefix: "+56",
    flag: "🇨🇱",
  },
  {
    countryCode: "CM",
    phonePrefix: "+237",
    flag: "🇨🇲",
  },
  {
    countryCode: "CN",
    phonePrefix: "+86",
    flag: "🇨🇳",
  },
  {
    countryCode: "CO",
    phonePrefix: "+57",
    flag: "🇨🇴",
  },
  {
    countryCode: "CR",
    phonePrefix: "+506",
    flag: "🇨🇷",
  },
  {
    countryCode: "CU",
    phonePrefix: "+53",
    flag: "🇨🇺",
  },
  {
    countryCode: "CV",
    phonePrefix: "+238",
    flag: "🇨🇻",
  },
  {
    countryCode: "CX",
    phonePrefix: "+61",
    flag: "🇨🇽",
  },
  {
    countryCode: "CY",
    phonePrefix: "+357",
    flag: "🇨🇾",
  },
  {
    countryCode: "CZ",
    phonePrefix: "+420",
    flag: "🇨🇿",
  },
  {
    countryCode: "DE",
    phonePrefix: "+49",
    flag: "🇩🇪",
  },
  {
    countryCode: "DJ",
    phonePrefix: "+253",
    flag: "🇩🇯",
  },
  {
    countryCode: "DM",
    phonePrefix: "+1767",
    flag: "🇩🇲",
  },
  {
    countryCode: "DO",
    phonePrefix: "+1849",
    flag: "🇩🇴",
  },
  {
    countryCode: "DZ",
    phonePrefix: "+213",
    flag: "🇩🇿",
  },
  {
    countryCode: "EC",
    phonePrefix: "+593",
    flag: "🇪🇨",
  },
  {
    countryCode: "EE",
    phonePrefix: "+372",
    flag: "🇪🇪",
  },
  {
    countryCode: "EG",
    phonePrefix: "+20",
    flag: "🇪🇬",
  },
  {
    countryCode: "ER",
    phonePrefix: "+291",
    flag: "🇪🇷",
  },
  {
    countryCode: "ES",
    phonePrefix: "+34",
    flag: "🇪🇸",
  },
  {
    countryCode: "ET",
    phonePrefix: "+251",
    flag: "🇪🇹",
  },
  {
    countryCode: "FJ",
    phonePrefix: "+679",
    flag: "🇫🇯",
  },
  {
    countryCode: "FK",
    phonePrefix: "+500",
    flag: "🇫🇰",
  },
  {
    countryCode: "FM",
    phonePrefix: "+691",
    flag: "🇫🇲",
  },
  {
    countryCode: "FO",
    phonePrefix: "+298",
    flag: "🇫🇴",
  },
  {
    countryCode: "FR",
    phonePrefix: "+33",
    flag: "🇫🇷",
  },
  {
    countryCode: "GA",
    phonePrefix: "+241",
    flag: "🇬🇦",
  },
  {
    countryCode: "GB",
    phonePrefix: "+44",
    flag: "🇬🇧",
  },
  {
    countryCode: "GD",
    phonePrefix: "+1473",
    flag: "🇬🇩",
  },
  {
    countryCode: "GE",
    phonePrefix: "+995",
    flag: "🇬🇪",
  },
  {
    countryCode: "GF",
    phonePrefix: "+594",
    flag: "🇬🇫",
  },
  {
    countryCode: "GG",
    phonePrefix: "+44",
    flag: "🇬🇬",
  },
  {
    countryCode: "GH",
    phonePrefix: "+233",
    flag: "🇬🇭",
  },
  {
    countryCode: "GI",
    phonePrefix: "+350",
    flag: "🇬🇮",
  },
  {
    countryCode: "GL",
    phonePrefix: "+299",
    flag: "🇬🇱",
  },
  {
    countryCode: "GM",
    phonePrefix: "+220",
    flag: "🇬🇲",
  },
  {
    countryCode: "GN",
    phonePrefix: "+224",
    flag: "🇬🇳",
  },
  {
    countryCode: "GP",
    phonePrefix: "+590",
    flag: "🇬🇵",
  },
  {
    countryCode: "GQ",
    phonePrefix: "+240",
    flag: "🇬🇶",
  },
  {
    countryCode: "GR",
    phonePrefix: "+30",
    flag: "🇬🇷",
  },
  {
    countryCode: "GS",
    phonePrefix: "+500",
    flag: "🇬🇸",
  },
  {
    countryCode: "GT",
    phonePrefix: "+502",
    flag: "🇬🇹",
  },
  {
    countryCode: "GU",
    phonePrefix: "+1671",
    flag: "🇬🇺",
  },
  {
    countryCode: "GW",
    phonePrefix: "+245",
    flag: "🇬🇼",
  },
  {
    countryCode: "GY",
    phonePrefix: "+595",
    flag: "🇬🇾",
  },
  {
    countryCode: "HK",
    phonePrefix: "+852",
    flag: "🇭🇰",
  },
  {
    countryCode: "HN",
    phonePrefix: "+504",
    flag: "🇭🇳",
  },
  {
    countryCode: "HR",
    phonePrefix: "+385",
    flag: "🇭🇷",
  },
  {
    countryCode: "HT",
    phonePrefix: "+509",
    flag: "🇭🇹",
  },
  {
    countryCode: "HU",
    phonePrefix: "+36",
    flag: "🇭🇺",
  },
  {
    countryCode: "ID",
    phonePrefix: "+62",
    flag: "🇮🇩",
  },
  {
    countryCode: "IE",
    phonePrefix: "+353",
    flag: "🇮🇪",
  },
  {
    countryCode: "IL",
    phonePrefix: "+972",
    flag: "🇮🇱",
  },
  {
    countryCode: "IM",
    phonePrefix: "+44",
    flag: "🇮🇲",
  },
  {
    countryCode: "IN",
    phonePrefix: "+91",
    flag: "🇮🇳",
  },
  {
    countryCode: "IO",
    phonePrefix: "+246",
    flag: "🇮🇴",
  },
  {
    countryCode: "IQ",
    phonePrefix: "+964",
    flag: "🇮🇷",
  },
  {
    countryCode: "IR",
    phonePrefix: "+98",
    flag: "🇮🇷",
  },
  {
    countryCode: "IS",
    phonePrefix: "+354",
    flag: "🇮🇸",
  },
  {
    countryCode: "IT",
    phonePrefix: "+39",
    flag: "🇮🇹",
  },
  {
    countryCode: "JE",
    phonePrefix: "+44",
    flag: "🇯🇪",
  },
  {
    countryCode: "JM",
    phonePrefix: "+1876",
    flag: "🇯🇲",
  },
  {
    countryCode: "JO",
    phonePrefix: "+962",
    flag: "🇯🇴",
  },
  {
    countryCode: "JP",
    phonePrefix: "+81",
    flag: "🇯🇵",
  },
  {
    countryCode: "KE",
    phonePrefix: "+254",
    flag: "🇰🇪",
  },
  {
    countryCode: "KG",
    phonePrefix: "+996",
    flag: "🇰🇬",
  },
  {
    countryCode: "KH",
    phonePrefix: "+855",
    flag: "🇰🇭",
  },
  {
    countryCode: "KI",
    phonePrefix: "+686",
    flag: "🇰🇮",
  },
  {
    countryCode: "KM",
    phonePrefix: "+269",
    flag: "🇰🇲",
  },
  {
    countryCode: "KN",
    phonePrefix: "+1869",
    flag: "🇰🇳",
  },
  {
    countryCode: "KP",
    phonePrefix: "+850",
    flag: "🇰🇵",
  },
  {
    countryCode: "KR",
    phonePrefix: "+82",
    flag: "🇰🇷",
  },
  {
    countryCode: "KW",
    phonePrefix: "+965",
    flag: "🇰🇼",
  },
  {
    countryCode: "KY",
    phonePrefix: "+ 345",
    flag: "🇰🇾",
  },
  {
    countryCode: "KZ",
    phonePrefix: "+77",
    flag: "🇰🇿",
  },
  {
    countryCode: "LA",
    phonePrefix: "+856",
    flag: "🇱🇦",
  },
  {
    countryCode: "LB",
    phonePrefix: "+961",
    flag: "🇱🇧",
  },
  {
    countryCode: "LC",
    phonePrefix: "+1758",
    flag: "🇱🇨",
  },
  {
    countryCode: "LI",
    phonePrefix: "+423",
    flag: "🇱🇮",
  },
  {
    countryCode: "LK",
    phonePrefix: "+94",
    flag: "🇱🇰",
  },
  {
    countryCode: "LR",
    phonePrefix: "+231",
    flag: "🇱🇷",
  },
  {
    countryCode: "LS",
    phonePrefix: "+266",
    flag: "🇱🇸",
  },
  {
    countryCode: "LT",
    phonePrefix: "+370",
    flag: "🇱🇹",
  },
  {
    countryCode: "LU",
    phonePrefix: "+352",
    flag: "🇱🇺",
  },
  {
    countryCode: "LV",
    phonePrefix: "+371",
    flag: "🇱🇻",
  },
  {
    countryCode: "LY",
    phonePrefix: "+218",
    flag: "🇱🇾",
  },
  {
    countryCode: "MA",
    phonePrefix: "+212",
    flag: "🇲🇦",
  },
  {
    countryCode: "MC",
    phonePrefix: "+377",
    flag: "🇲🇨",
  },
  {
    countryCode: "MD",
    phonePrefix: "+373",
    flag: "🇲🇩",
  },
  {
    countryCode: "ME",
    phonePrefix: "+382",
    flag: "🇲🇪",
  },
  {
    countryCode: "MF",
    phonePrefix: "+590",
    flag: "🇲🇫",
  },
  {
    countryCode: "MG",
    phonePrefix: "+261",
    flag: "🇲🇬",
  },
  {
    countryCode: "MH",
    phonePrefix: "+692",
    flag: "🇲🇭",
  },
  {
    countryCode: "MK",
    phonePrefix: "+389",
    flag: "🇲🇰",
  },
  {
    countryCode: "ML",
    phonePrefix: "+223",
    flag: "🇲🇱",
  },
  {
    countryCode: "MM",
    phonePrefix: "+95",
    flag: "🇲🇲",
  },
  {
    countryCode: "MN",
    phonePrefix: "+976",
    flag: "🇲🇳",
  },
  {
    countryCode: "MO",
    phonePrefix: "+853",
    flag: "🇲🇴",
  },
  {
    countryCode: "MP",
    phonePrefix: "+1670",
    flag: "🇲🇵",
  },
  {
    countryCode: "MQ",
    phonePrefix: "+596",
    flag: "🇲🇶",
  },
  {
    countryCode: "MR",
    phonePrefix: "+222",
    flag: "🇲🇷",
  },
  {
    countryCode: "MS",
    phonePrefix: "+1664",
    flag: "🇲🇸",
  },
  {
    countryCode: "MT",
    phonePrefix: "+356",
    flag: "🇲🇹",
  },
  {
    countryCode: "MU",
    phonePrefix: "+230",
    flag: "🇲🇺",
  },
  {
    countryCode: "MV",
    phonePrefix: "+960",
    flag: "🇲🇻",
  },
  {
    countryCode: "MW",
    phonePrefix: "+265",
    flag: "🇲🇼",
  },
  {
    countryCode: "MX",
    phonePrefix: "+52",
    flag: "🇲🇽",
  },
  {
    countryCode: "MY",
    phonePrefix: "+60",
    flag: "🇲🇾",
  },
  {
    countryCode: "MZ",
    phonePrefix: "+258",
    flag: "🇲🇿",
  },
  {
    countryCode: "NA",
    phonePrefix: "+264",
    flag: "🇳🇦",
  },
  {
    countryCode: "NC",
    phonePrefix: "+687",
    flag: "🇳🇨",
  },
  {
    countryCode: "NE",
    phonePrefix: "+227",
    flag: "🇳🇪",
  },
  {
    countryCode: "NF",
    phonePrefix: "+672",
    flag: "🇳🇫",
  },
  {
    countryCode: "NG",
    phonePrefix: "+234",
    flag: "🇳🇬",
  },
  {
    countryCode: "NI",
    phonePrefix: "+505",
    flag: "🇳🇮",
  },
  {
    countryCode: "NL",
    phonePrefix: "+31",
    flag: "🇳🇱",
  },
  {
    countryCode: "NP",
    phonePrefix: "+977",
    flag: "🇳🇵",
  },
  {
    countryCode: "NR",
    phonePrefix: "+674",
    flag: "🇳🇷",
  },
  {
    countryCode: "NU",
    phonePrefix: "+683",
    flag: "🇳🇺",
  },
  {
    countryCode: "NZ",
    phonePrefix: "+64",
    flag: "🇳🇿",
  },
  {
    countryCode: "OM",
    phonePrefix: "+968",
    flag: "🇴🇲",
  },
  {
    countryCode: "PA",
    phonePrefix: "+507",
    flag: "🇵🇦",
  },
  {
    countryCode: "PE",
    phonePrefix: "+51",
    flag: "🇵🇪",
  },
  {
    countryCode: "PF",
    phonePrefix: "+689",
    flag: "🇵🇫",
  },
  {
    countryCode: "PG",
    phonePrefix: "+675",
    flag: "🇵🇬",
  },
  {
    countryCode: "PH",
    phonePrefix: "+63",
    flag: "🇵🇭",
  },
  {
    countryCode: "PK",
    phonePrefix: "+92",
    flag: "🇵🇰",
  },
  {
    countryCode: "PL",
    phonePrefix: "+48",
    flag: "🇵🇱",
  },
  {
    countryCode: "PM",
    phonePrefix: "+508",
    flag: "🇵🇲",
  },
  {
    countryCode: "PN",
    phonePrefix: "+872",
    flag: "🇵🇳",
  },
  {
    countryCode: "PR",
    phonePrefix: "+1939",
    flag: "🇵🇷",
  },
  {
    countryCode: "PS",
    phonePrefix: "+970",
    flag: "🇵🇸",
  },
  {
    countryCode: "PT",
    phonePrefix: "+351",
    flag: "🇵🇹",
  },
  {
    countryCode: "PW",
    phonePrefix: "+680",
    flag: "🇵🇼",
  },
  {
    countryCode: "PY",
    phonePrefix: "+595",
    flag: "🇵🇾",
  },
  {
    countryCode: "QA",
    phonePrefix: "+974",
    flag: "🇶🇦",
  },
  {
    countryCode: "RE",
    phonePrefix: "+262",
    flag: "🇷🇪",
  },
  {
    countryCode: "RO",
    phonePrefix: "+40",
    flag: "🇷🇴",
  },
  {
    countryCode: "RS",
    phonePrefix: "+381",
    flag: "🇷🇸",
  },
  {
    countryCode: "RU",
    phonePrefix: "+7",
    flag: "🇷🇺",
  },
  {
    countryCode: "RW",
    phonePrefix: "+250",
    flag: "🇷🇼",
  },
  {
    countryCode: "SA",
    phonePrefix: "+966",
    flag: "🇸🇦",
  },
  {
    countryCode: "SB",
    phonePrefix: "+677",
    flag: "🇸🇧",
  },
  {
    countryCode: "SC",
    phonePrefix: "+248",
    flag: "🇸🇨",
  },
  {
    countryCode: "SD",
    phonePrefix: "+249",
    flag: "🇸🇩",
  },
  {
    countryCode: "SG",
    phonePrefix: "+65",
    flag: "🇸🇬",
  },
  {
    countryCode: "SH",
    phonePrefix: "+290",
    flag: "🇸🇭",
  },
  {
    countryCode: "SI",
    phonePrefix: "+386",
    flag: "🇸🇮",
  },
  {
    countryCode: "SJ",
    phonePrefix: "+47",
    flag: "🇸🇯",
  },
  {
    countryCode: "SK",
    phonePrefix: "+421",
    flag: "🇸🇰",
  },
  {
    countryCode: "SL",
    phonePrefix: "+232",
    flag: "🇸🇱",
  },
  {
    countryCode: "SM",
    phonePrefix: "+378",
    flag: "🇸🇲",
  },
  {
    countryCode: "SN",
    phonePrefix: "+221",
    flag: "🇸🇳",
  },
  {
    countryCode: "SO",
    phonePrefix: "+252",
    flag: "🇸🇴",
  },
  {
    countryCode: "SR",
    phonePrefix: "+597",
    flag: "🇸🇷",
  },
  {
    countryCode: "SS",
    phonePrefix: "+211",
    flag: "🇸🇸",
  },
  {
    countryCode: "ST",
    phonePrefix: "+239",
    flag: "🇸🇹",
  },
  {
    countryCode: "SV",
    phonePrefix: "+503",
    flag: "🇸🇻",
  },
  {
    countryCode: "SY",
    phonePrefix: "+963",
    flag: "🇸🇾",
  },
  {
    countryCode: "SZ",
    phonePrefix: "+268",
    flag: "🇸🇿",
  },
  {
    countryCode: "TC",
    phonePrefix: "+1649",
    flag: "🇹🇨",
  },
  {
    countryCode: "TD",
    phonePrefix: "+235",
    flag: "🇹🇩",
  },
  {
    countryCode: "TG",
    phonePrefix: "+228",
    flag: "🇹🇬",
  },
  {
    countryCode: "TH",
    phonePrefix: "+66",
    flag: "🇹🇭",
  },
  {
    countryCode: "TJ",
    phonePrefix: "+992",
    flag: "🇹🇯",
  },
  {
    countryCode: "TK",
    phonePrefix: "+690",
    flag: "🇹🇰",
  },
  {
    countryCode: "TL",
    phonePrefix: "+670",
    flag: "🇹🇱",
  },
  {
    countryCode: "TM",
    phonePrefix: "+993",
    flag: "🇹🇲",
  },
  {
    countryCode: "TN",
    phonePrefix: "+216",
    flag: "🇹🇳",
  },
  {
    countryCode: "TO",
    phonePrefix: "+676",
    flag: "🇹🇴",
  },
  {
    countryCode: "TR",
    phonePrefix: "+90",
    flag: "🇹🇷",
  },
  {
    countryCode: "TT",
    phonePrefix: "+1868",
    flag: "🇹🇹",
  },
  {
    countryCode: "TV",
    phonePrefix: "+688",
    flag: "🇹🇻",
  },
  {
    countryCode: "TW",
    phonePrefix: "+886",
    flag: "🇹🇼",
  },
  {
    countryCode: "TZ",
    phonePrefix: "+255",
    flag: "🇹🇿",
  },
  {
    countryCode: "UA",
    phonePrefix: "+380",
    flag: "🇺🇦",
  },
  {
    countryCode: "UG",
    phonePrefix: "+256",
    flag: "🇺🇬",
  },
  {
    countryCode: "US",
    phonePrefix: "+1",
    flag: "🇺🇸",
  },
  {
    countryCode: "UY",
    phonePrefix: "+598",
    flag: "🇺🇾",
  },
  {
    countryCode: "UZ",
    phonePrefix: "+998",
    flag: "🇺🇿",
  },
  {
    countryCode: "VA",
    phonePrefix: "+379",
    flag: "🇻🇦",
  },
  {
    countryCode: "VC",
    phonePrefix: "+1784",
    flag: "🇻🇨",
  },
  {
    countryCode: "VE",
    phonePrefix: "+58",
    flag: "🇻🇪",
  },
  {
    countryCode: "VG",
    phonePrefix: "+1284",
    flag: "🇻🇬",
  },
  {
    countryCode: "VI",
    phonePrefix: "+1340",
    flag: "🇻🇮",
  },
  {
    countryCode: "VN",
    phonePrefix: "+84",
    flag: "🇻🇳",
  },
  {
    countryCode: "VU",
    phonePrefix: "+678",
    flag: "🇻🇺",
  },
  {
    countryCode: "WF",
    phonePrefix: "+681",
    flag: "🇼🇫",
  },
  {
    countryCode: "WS",
    phonePrefix: "+685",
    flag: "🇼🇸",
  },
  {
    countryCode: "YE",
    phonePrefix: "+967",
    flag: "🇾🇪",
  },
  {
    countryCode: "YT",
    phonePrefix: "+262",
    flag: "🇾🇹",
  },
  {
    countryCode: "ZA",
    phonePrefix: "+27",
    flag: "🇿🇦",
  },
  {
    countryCode: "ZM",
    phonePrefix: "+260",
    flag: "🇿🇲",
  },
  {
    countryCode: "ZW",
    phonePrefix: "+263",
    flag: "🇿🇼",
  },
];
