import { useContext, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";

import Cookie from "js-cookie";
import moment from "moment";

import {
  EnsureValidLocale,
  Routes,
  SearchHandler,
  StripeHandler,
} from "components/app";
import { ErrorBoundary, Layout } from "components/common/wrappers";
import { PopoverProvider } from "providers/PopoverHandler";
import { PopupProvider } from "providers/PopupHandler";
import { IntlContext } from "providers/i18n";
import { getParsedJSON, useRoutePaths } from "utils";

const {
  REACT_APP_PROD_REDIRECTION_URL,
  REACT_APP_PAGE_TITLE,
  REACT_APP_BRAND,
  REACT_APP_GOOGLE_PLACES_API_KEY,
  // REACT_APP_HUBSPOT_ID,
  // REACT_APP_COOKIE_YES_ID,
  // REACT_APP_GA_MEASUREMENT_ID,
} = process.env;

export default (): JSX.Element => {
  const { currentLocale } = useContext(IntlContext);

  const getRoutePath = useRoutePaths();

  const isSignaturePage = window.location.pathname.includes(
    getRoutePath({
      module: "signmore",
      routeName: "SIGN_DOCUMENT_WIZARD",
      arg1: "",
    })
  );
  const isIframe = window.location !== window.parent.location;
  const isStandalone = isSignaturePage || isIframe;

  // Use current locale for date formatting
  useEffect(() => {
    moment.locale(currentLocale);
  }, [currentLocale]);

  // Redirect users without test access to production
  useEffect(() => {
    if (!REACT_APP_PROD_REDIRECTION_URL) return;
    if (isStandalone) return;

    const cookie = Cookie.get("dp-test-cookie");
    const parsedCookie = getParsedJSON(cookie);
    const isTest = (parsedCookie as { active?: boolean } | null)?.active;

    if (!isTest) window.location.assign(REACT_APP_PROD_REDIRECTION_URL);
  }, [REACT_APP_PROD_REDIRECTION_URL]);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`${REACT_APP_PAGE_TITLE} | %s`}
        defaultTitle={REACT_APP_PAGE_TITLE}
        htmlAttributes={{ lang: currentLocale }}
      >
        {REACT_APP_BRAND === "payoutpartner" && (
          <style type="text/css">{`
            :root {
              --primary: #00D382;
              --primary-hover: #00B36E;
              --primary-contrast: #01382E;
            }
         `}</style>
        )}

        {/* Used for AddressSearch */}
        <script
          async
          src={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_PLACES_API_KEY}&loading=async&libraries=places&callback=Function.prototype`}
        />

        {/* CookieYes */}
        {/* {REACT_APP_COOKIE_YES_ID && (
          <script
            id="cookieyes"
            type="text/javascript"
            src={`https://cdn-cookieyes.com/client_data/${REACT_APP_COOKIE_YES_ID}/script.js`}
          />
        )} */}

        {/* Google Analytics */}
        {/* {REACT_APP_GA_MEASUREMENT_ID && (
          <script
            id="google-analytics"
            type="text/javascript"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GA_MEASUREMENT_ID}`}
          />
        )} */}

        {/* HubSpot */}
        {/* {REACT_APP_HUBSPOT_ID && (
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src={`//js.hs-scripts.com/${REACT_APP_HUBSPOT_ID}.js`}
          />
        )} */}
      </Helmet>

      <Router>
        <PopupProvider>
          <Toaster position="top-right" />

          <PopoverProvider>
            <SearchHandler />
            <EnsureValidLocale />
            <StripeHandler />

            <Layout>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </Layout>
          </PopoverProvider>
        </PopupProvider>
      </Router>
    </HelmetProvider>
  );
};
